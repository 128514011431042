import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";

// import Bio from "../components/bio"
import Layout from "../components/layout";
import SEO from "../components/seo";
// import { rhythm, scale } from "../utils/typography"

import s from "./s.module.scss";

const BlogPostTemplate = props => {
  useEffect(() => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (width > 1080) window.addEventListener("scroll", scrollListener);

    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  });

  const scrollListener = e => {
    const scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (scrollTop > 50) {
      document.querySelector("#title").style.fontSize = "1.875rem";
      document.querySelector(".site_header").style.marginTop = "0";
      document.querySelector("nav").style.marginTop = "0.5rem";
    } else {
      document.querySelector("#title").style.fontSize = "3.5rem";
      document.querySelector(".site_header").style.marginTop = "1rem";
      document.querySelector("nav").style.marginTop = "1.5rem";
    }
  };

  const post = props.data.markdownRemark;
  const siteTitle = props.data.site.siteMetadata.title;

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article className={s.blogPost}>
        <header>
          <h1
            style={{
              // marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p className={s.blogPostDate}>{post.frontmatter.date}</p>
        </header>
        <section
          className={s.blogTextContainer}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </article>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
